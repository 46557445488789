const packageDeviceHeading = 'Follow the instructions on how to safely package your Apple product';
const packageDeviceHeadingDyson =
  'Follow the instructions on how to safely package your Dyson product';

const packagingInstructions = [
  'Please take photos of your product prior to packaging to reflect current condition.',
  'Please take the product (complete with accessories) to the local Australia Post office with the shipping label or QR code (refer to Step 4 – Send Product).',
  'Australia Post will provide you with the appropriate box and bubble wrap to safely send your unit.',
  'Ensure you wrap the unit around all sides with bubble wrap to prevent any impact damage.',
  'Once packed safely and securely at the Australia Post office, please take the package to the counter to send off.',
  'Australia Post will provide you with a send receipt for your record.',
];
const packagingInstructionsVar1 = [
  'Please take photos of your product prior to packaging to reflect the current condition.',
  'Australia Post will provide you with the appropriate box and bubble wrap to safely send your product where you have not been able to use the original packaging.',
  'Ensure you wrap the unit around all sides with bubble wrap to prevent any impact damage.',
  'Once packed safely and securely at the Australia Post office, please take the package to the counter to send off. (refer to Tab 4 – Send Product).',
  'Australia Post will provide you with a send receipt for your records. Please ensure you retain this receipt for future reference.',
];
const packagingInstructionsVar2 = [
  'Please take photos of your product prior to packaging to reflect the current condition.',
  'Please take the product (complete with accessories excluding the wand) to the local Australia Post office with the shipping label or QR code (refer to Tab 4 – Send Product).  <u><strong>** PLEASE DO NOT SEND THE WAND **.</strong></u.',
  'Australia Post will provide you with the appropriate box and bubble wrap to safely send your product. <u><strong>** PLEASE DO NOT SEND THE WAND **.</strong></u>',
  'Ensure you wrap the product around all sides with bubble wrap to prevent any impact damage.',
  'Once packed safely and securely at the Australia Post office, please take the package to the counter to send off.',
  'Australia Post will provide you with a send receipt for your records. Please ensure you retain this receipt for future reference',
];
const packagingInstructionsVar3 = [
  'Please take photos of your product prior to packaging to reflect the current condition.',
  "Package all of your product parts (including your accessories and charger) back into the original manufacturer's box.",
  'Ensure you wrap the product around all sides with bubble wrap to prevent any impact damage.',
  'Please take the packaged product (complete with accessories including the wand) to the local Australia Post office with the shipping label or QR code (refer to Tab 4 – Send Product).',
  'Once at the Australia Post office, please take the package to the counter to send off.',
  'Australia Post will provide you with a send receipt for your records. Please ensure you retain this receipt for future reference.',
];
const packagingInstructionsVar4 = [
  'Please take photos of your product prior to packaging to reflect current condition.',
  'If you have the original packaging, please wrap the item in multiple layers of bubble wrap so that a cushioning layer with at least 6cm of thickness protects the product when in transit.',
  'If you don’t have the original packaging, please source a strong box that is strong enough to protect the item. Product Care suggests a dual-wall box for heavier items.',
  'Place filler material such as packing peanuts, polystyrene or bubble wrap, between the bubble wrapped product and the wall of the box, particularly around the corners and edges of the product.',
  'Please take the packaged product to the nearest Australia Post with the shipping label or barcode (refer to Tab 4 – Send Product).',
  'Australia Post will provide you with a send receipt for your records. Please ensure you retain this receipt for future reference.',
];
const redirectUrl = 'https://return.auspost.com.au';
const reviveBox1 = '/REVIVE_TECHNOLOGIES_BOX1';
const reviveBox2 = '/REVIVE_TECHNOLOGIES_BOX_2';
const reviveBox3 = '/REVIVE_TECHNOLOGIES_BOX_3';
const reviveBoxIntl = '/REVIVE_TECHNOLOGIES_INTERNATIONAL';
const notes =
  'NOTE: Australia Post will not provide a box for your Dyson product, if you have not kept the original box, please source a suitable box according to the above specifications.';

export default {
  apple: {
    iphone: {
      route: 'iphone',
      name: 'iPhone',
      label: 'All iPhone models',
      packageDeviceHeading,
      packagingInstructions,
      redirect: `${redirectUrl}${reviveBox1}`,
    },
    appleWatch: {
      route: 'apple-watch',
      name: 'Apple Watch',
      label: 'All Apple Watch models',
      packageDeviceHeading,
      packagingInstructions,
      redirect: `${redirectUrl}${reviveBox1}`,
    },
    iPad: {
      route: 'ipad',
      name: 'iPads',
      label: 'All iPad models <br /><small>(excluding iPad Pro 12")</small>',
      packageDeviceHeading,
      packagingInstructions,
      redirect: `${redirectUrl}${reviveBox2}`,
    },
    iPadPro: {
      route: 'ipad-pro',
      name: ' iPad Pro 12"',
      label: 'Apple iPad Pro 12"',
      packageDeviceHeading,
      packagingInstructions,
      redirect: `${redirectUrl}${reviveBox3}`,
    },
    iMac: {
      route: 'imac',
      name: 'iMac, iMac Pro',
      label: 'All Apple Desktop computers',
      packageDeviceHeading: 'Follow the instructions on how to safely package your iMac product',
      packagingInstructions: [
        'Please take photos of your product prior to packaging to reflect current condition.',
        'First option is to use the original Manufacturer’s packaging.',
        'If the original packaging is no longer available or damaged, wrap the item in multiple layers of bubble wrap so that a cushioning layer with at least 6cm of thickness protects the item.',
        'Ensure all corners, front, rear and the base of the product are protected.',
        'Source a strong outer box which is at least 14cm larger than the inner box in all dimensions. This box should be strong enough to protect the item. Product Care suggests a dual-wall box for heavier items.',
        'Where possible place filler material such as filler peanuts, polystyrene or bubble wrap, between the bubble wrapped item and the wall of the box, particularly around the corners and edges of the unit.',
        'Before closing and sealing, please include a copy of the email you received from Product Care, inside the box. Tape all seams or flaps.',
        'Please take the packaged unit to the nearest Australia Post with the shipping label or barcode (refer to Step 4 – Send Product).',
      ],
      note: 'NOTE: Australia Post will not provide a box for your iMac product, if you have not kept the original iMac box please source a suitable box according to the above specifications.',
      redirect: `${redirectUrl}${reviveBoxIntl}`,
    },
    macbook: {
      route: 'macbook',
      name: 'MacBooks',
      label: 'Macbook Air, Mac Mini',
      packageDeviceHeading,
      packagingInstructions,
      redirect: `${redirectUrl}${reviveBox3}`,
    },
  },
  dyson: {
    stickVacuums: {
      route: 'stick-vacuums',
      name: 'All Stick Vacuums',
      label: 'All Stick Vacuum models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions,
      packagingInstructionsVar2,
      packagingInstructionsVar3,
      redirect: redirectUrl,
    },
    barrelVacuums: {
      route: 'barrel-vacuums',
      name: 'Barrel Vacuums',
      label: 'All Barrel Vacuum models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions: packagingInstructionsVar4,
      note: notes,
      redirect: redirectUrl,
    },
    uprightVacuums: {
      route: 'upright-vacuums',
      name: 'Upright Vacuums',
      label: 'All Upright Vacuum models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions: packagingInstructionsVar4,
      redirect: redirectUrl,
      note: notes,
    },
    hairDryer: {
      route: 'hair-dryer',
      name: 'Hair Dryers',
      label: 'All Hair Dryer models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions,
      packagingInstructionsVar1,
      packagingInstructionsVar3,
      redirect: redirectUrl,
    },
    airWrap: {
      route: 'air-wrap',
      name: 'Air Wraps',
      label: 'All Air Wrap models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions,
      packagingInstructionsVar1,
      packagingInstructionsVar3,
      redirect: redirectUrl,
    },
    airPurifiers: {
      route: 'air-purifiers',
      name: 'Air Purifiers / Fans',
      label: 'All Air Purifier / Fan models',
      packageDeviceHeading: packageDeviceHeadingDyson,
      packagingInstructions: packagingInstructionsVar4,
      note: notes,
      redirect: redirectUrl,
    },
  },
};
